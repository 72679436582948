import React, { useState } from 'react';
import {
  Grid,
  SuccessNotification,
  ErrorNotification,
  Field,
  ErrorLabel,
  Column,
  PrimaryLoadingButton
} from 'components';
import Itly from 'itly';
import { Formik } from 'formik';
import type { FormikErrors, FormikActions } from 'formik';
import { isValidEmail } from 'helpers/strings';
import { customerLogin, getCustomer } from 'services/magenum/customer';
import {
  StyledRegisterForm,
  CenteredHeader,
  StyledDiv,
  InlineP,
  StyledLogo,
  PasswordMaskButtonWithIcon,
  RelativeStyleLabel,
  SloganText
} from 'components/CustomerRegistration/styled';
import { fetchCustomerSuccess, fetchCustomerPaymentMethodsRequest, customerLoginSuccess } from 'store/customer/actions';
import { hideModal as _hideModal, openCustomerRegisterModal as _openCustomerRegisterModal } from 'store/modal/actions';
import { fetchCartRequest } from 'store/cart/actions';
import { useDispatch } from 'react-redux';
import { nrError } from 'helpers/reporting/newrelic';
import { analyticsWrapper } from 'analytics/itly/analyticsWrapper';
import { TrackingProp } from 'react-tracking';
import { trackLoggedIn } from 'analytics/itly/tracking';
import { getMagentoBaseUrl } from 'helpers/env';
import StyledGoogleButton from 'components/GoogleButton/StyledGoogleButton';
import { getGoogleRedirectURL, redirectToPage } from 'helpers/navigation';
import LogoSvg from '../../icons/Logo.svg';
import SvgContainer from 'icons/SvgContainer';
import { useProductSelectors } from 'hooks/useProductSelectors';
import { translateErrorMessage } from 'helpers/customer/login';
import { Show } from 'components/Functional';

interface CustomerLoginProps {
  tracking?: TrackingProp;
  redirect?: {
    redirect: string;
  };
}

interface LoginFields {
  email: string;
  password: string;
}

const MOBILE_APP_USER_AGENTS = ['fban', 'fbav', 'instagram'];

const CustomerLogin = ({ redirect, tracking }: CustomerLoginProps) => {
  const dispatch = useDispatch();
  const { getEditorVersion } = useProductSelectors();

  const hideModal = () => dispatch(_hideModal());
  const openCustomerRegisterModal = () => {
    Itly.accountCreateStarted({});
    dispatch(_openCustomerRegisterModal(redirect));
  };
  const setCustomer = customer => dispatch(fetchCustomerSuccess(customer));
  const getCart = () => dispatch(fetchCartRequest());
  const getCustomerPaymentMethods = () => dispatch(fetchCustomerPaymentMethodsRequest());

  const [passwordMaskIcon, setPasswordMaskIcon] = useState<'openEye' | 'closedEye'>('closedEye');
  const [passwordFieldType, setPasswordFieldType] = useState<'text' | 'password'>('password');

  const [googleButtonLoadingIndicator, showGoogleButtonLoadingIndicator] = useState(false);

  const isInAppBrowser = () => {
    const userAgent = navigator.userAgent;
    return MOBILE_APP_USER_AGENTS.some(mobileAppAgent => userAgent.toLowerCase().includes(mobileAppAgent));
  };

  const shouldHideSocialSignIn = isInAppBrowser();

  const receiveMessage = async event => {
    const { data } = event;
    if (data?.source === 'au-google-auth-popup' && data?.postAuthRedirect) {
      let getCustomerResponse, getCartResponse, getCustomerPaymentMethodsResponse;

      try {
        redirectToPage(data.postAuthRedirect);
        getCustomerResponse = getCustomer();
        getCartResponse = getCart();
        getCustomerPaymentMethodsResponse = getCustomerPaymentMethods();
        setTimeout(() => {
          hideModal();
        }, 1000);
      } catch (error) {
        nrError('Google Login error', error, {
          getCustomerResponse,
          getCartResponse,
          getCustomerPaymentMethodsResponse
        });
      }
    }
  };

  const togglePasswordMask = () => {
    // Toggle the type attribute
    const nextType = passwordFieldType === 'password' ? 'text' : 'password';
    setPasswordFieldType(nextType);

    // Toggle the eye
    const nextIcon = passwordMaskIcon === 'closedEye' ? 'openEye' : 'closedEye';
    setPasswordMaskIcon(nextIcon);
  };

  const handlePasswordResetClick = () => {
    redirectToPage(`${getMagentoBaseUrl()}/customer/account/forgotpassword/`);
    Itly.passwordResetStarted();
  };

  const handleLoginWithGoogle = () => {
    showGoogleButtonLoadingIndicator(true);
    Itly.loginAttempted({ login_method: 'Google' });

    const width = 500;
    const height = 400;
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2.5;
    const title = 'Google Auth Window';

    if (redirect) {
      // modal was not launched on /login page
      const url = getGoogleRedirectURL(`${getMagentoBaseUrl()}/mooauth/actions/sendAuthorizationRequest/`, redirect.redirect);
      window.open(url, title, `width=${width},height=${height},left=${left},top=${top}`);
      window.addEventListener('message', receiveMessage, false);
    } else {
      // the OG way
      const url = getGoogleRedirectURL(`${getMagentoBaseUrl()}/mooauth/actions/sendAuthorizationRequest/`);
      redirectToPage(url);
    }
  };

  const isLogoutPage = window?.location?.href?.includes('/logout');

  if (isLogoutPage) {
    // magento logout page redirects back to /login, so we have to handle it here
    // ToDo: handle logout with an api call instead of redirect
    redirectToPage('/');
  }

  return (
    <>
      <CenteredHeader>
        <StyledLogo>{SvgContainer(LogoSvg)}</StyledLogo>
        <SloganText>Honor That Which is Meaningful</SloganText>
      </CenteredHeader>
      <Grid gutter={20} style={{ justifyContent: 'center' }}>
        <Column size={12}>
          <Show when={!shouldHideSocialSignIn}>
            <StyledGoogleButton
              description="Continue with Google"
              handleClick={handleLoginWithGoogle}
              disabled={googleButtonLoadingIndicator}
              isLoading={googleButtonLoadingIndicator}
              color="#000000"
            />
          </Show>
        </Column>
      </Grid>
      <Formik
        initialValues={
          {
            firstname: '',
            lastname: '',
            email: '',
            password: '',
            passwordConfirm: ''
          } as LoginFields
        }
        onSubmit={async (values: LoginFields, { setSubmitting, setStatus }: FormikActions<LoginFields>) => {
          let customerLoginResponse;
          try {
            Itly.loginAttempted({ login_method: 'Email' });
            customerLoginResponse = await customerLogin({ email: values.email, password: values.password });

            const customer = customerLoginResponse?.value?.customer;
            if (!customer) {
              throw new Error(translateErrorMessage(customerLoginResponse.value));
            }

            // Success! Show quick message, then close the modal
            setStatus({ success: 'You\'ve successfully logged in.' });
            setCustomer(customer);
            setSubmitting(false);
            getCart();
            getCustomerPaymentMethods();

            if (redirect) {
              const url = new URL(redirect.redirect);
              const urlParams = url.searchParams;
              const redirectTo = urlParams.get('redirectTo');
              const message = urlParams.get('message');
              const hammerCategory = urlParams.get('tryEditorV2ForHammerCategory');
              const sku = urlParams.get('tryEditorV2ForSku');
              const editorVersion = getEditorVersion(sku);

              dispatch(
                customerLoginSuccess({
                  editorVersion,
                  redirectTo: redirectTo || redirect.redirect,
                  hammerCategory,
                  message,
                  sku
                })
              );
            }
            setTimeout(() => {
              hideModal();
            }, 1000);
            trackLoggedIn(tracking, customer);
            Itly.loginCompleted({ login_method: 'Email', account_type: 'Existing' });
          } catch (error) {
            nrError('Login error', error, {
              customerLoginResponse,
              customerLoginResponseValue: JSON.stringify(customerLoginResponse?.value),
              email: values.email
            });

            // @todo Return magenum error message (currently magenum message isn't too useful.)
            const responseErrorMessage = error?.message || 'Incorrect email/password. Please try again.';
            // Error! Show the magento message or a generic failure message
            setStatus({ error: responseErrorMessage });

            setSubmitting(false);
          }
        }}
        validate={values => {
          const errors: FormikErrors<LoginFields> = {
            ...(!values.email && { email: 'Email address is required.' }),
            ...(!isValidEmail(values.email) && {
              email: 'Please enter a valid email address.'
            }),
            ...(!values.password && { password: 'Password is required.' })
          };
          return errors;
        }}
        validateOnChange={false}
        render={({ values, errors, touched, setFieldValue, isSubmitting, status, submitForm }) => (
          <StyledRegisterForm
            data-testid="customerLoginForm"
            onSubmit={e => {
              e.preventDefault();
              submitForm();
            }}
          >
            <Grid gutter={20}>
              {status && status.success ? (
                <Column size={12}>
                  <SuccessNotification message={status.success} />
                </Column>
              ) : null}
              <Column size={12}>
                <RelativeStyleLabel as="label">
                  {errors.email && touched.email && <ErrorLabel>{errors.email}</ErrorLabel>}
                  <Field
                    name="email"
                    type="email"
                    id="email"
                    autoFocus
                    placeholder="Email Address"
                    autoComplete="email"
                    value={values.email}
                    data-testid="customerLoginForm::email"
                    onChange={e => {
                      setFieldValue('email', e.target.value);
                    }}
                    border={errors.email && touched.email && '1px solid #C11E1E'}
                  />
                </RelativeStyleLabel>
              </Column>
              <Column size={12}>
                <RelativeStyleLabel as="label">
                  {errors.password && touched.password && <ErrorLabel>{errors.password}</ErrorLabel>}
                  <Field
                    name="password"
                    type={passwordFieldType}
                    id="password"
                    placeholder="Password"
                    autoComplete="password"
                    value={values.password}
                    data-testid="customerLoginForm::password"
                    onChange={e => {
                      setFieldValue('password', e.target.value);
                    }}
                    border={errors.password && touched.password && '1px solid #C11E1E'}
                  />
                  <PasswordMaskButtonWithIcon
                    icon={passwordMaskIcon}
                    onClick={() => togglePasswordMask()}
                    accessibilityText="Password Mask"
                  />
                </RelativeStyleLabel>
              </Column>
              <Column size={12}>
                <PrimaryLoadingButton
                  id="customer_login_button"
                  width="100%"
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  color="#333333"
                  hoverColor="#000"
                >
                  Sign In
                </PrimaryLoadingButton>
              </Column>
              {status && status.error ? (
                <Column size={12}>
                  <ErrorNotification message={status.error} />
                </Column>
              ) : null}
              <Column size={12} style={{ textAlign: 'center' }}>
                <StyledDiv>
                  <InlineP
                    id="createAccount"
                    onClick={() => openCustomerRegisterModal()}
                    onKeyDown={e => e.key === 'Enter' && openCustomerRegisterModal()}
                    secondary
                    alignment="center"
                    tabIndex={0}
                  >
                    Create Account
                  </InlineP>
                  |
                  <InlineP
                    onClick={() => handlePasswordResetClick()}
                    onKeyDown={e =>
                      e.key === 'Enter' && redirectToPage(`${getMagentoBaseUrl()}/customer/account/forgotpassword/`)
                    }
                    secondary
                    id="resetPasswordf"
                    alignment="center"
                    tabIndex={0}
                  >
                    Reset Password
                  </InlineP>
                </StyledDiv>
              </Column>
            </Grid>
          </StyledRegisterForm>
        )}
      />
    </>
  );
};

export default analyticsWrapper(CustomerLogin);
